import { gql } from "@apollo/client";

export const GET_EXPERT = gql`
  query getExpertPageData($id: Int!, $reviewsNumber: Int!) {
    getExpert(id: $id) {
      id
      uuid
      name
      description
      audioGreetingUrl
      cover {
        horizontal1x
        horizontal2x
        vertical1x
        vertical2x
        vertical3x
      }
      rates {
        videoCall
        phoneCall
        chat
      }
      gender
      userID
      rating
      skills {
        id
        name
        badge
        description
        expertsCount
      }
      methods {
        id
        name
        description
        expertsCount
      }
      totalSessionsCount
      experienceInYears
      title
      state
      reviewsCount
      trialAllowance {
      video
      chat
      phone
    }
    }
    getReviews(expertID: $id, first: $reviewsNumber) {
      edges {
        node {
          id
          text
          rating
          authorUser {
            image(size: 48) {
              onex
              twox
              threex
            }
            name
            id
          }
          createdAtTimestamp
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;
